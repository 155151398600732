import React, { useEffect, useState } from "react";
import classes from "./Style.module.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
import axios from "axios";

const Course = () => {
  const { id } = useParams();
  const [courseData, setcourseData] = useState();
  useEffect(async () => {
    AOS.init();
    const course = await axios.get(
      `https://buildfuturelabapi.herokuapp.com/api/courses/${id}`
    );
    setcourseData(course.data);
  }, []);
  return (
    <div className={classes.container}>
      <h1 data-aos="zoom-in" data-aos-duration="1000">
        {courseData?.title}
      </h1>
      <p data-aos="zoom-in" data-aos-duration="1000">
        {courseData?.content}
      </p>
      {courseData?.videos?.map((i) => (
        <div
          className={classes.player}
          data-aos="fade-up"
          data-aos-duration="1000"
          key={i._id}
        >
          <div className={classes.subPlayer}>
            <h1>{i.title}</h1>
            <p>{i.description}</p>
          </div>
          <ReactPlayer
            url={i.link}
            controls={true}
            width={window.innerWidth > 700 ? "700px" : "350px"}
            height={window.innerWidth > 700 ? "400px" : "200px"}
          />
        </div>
      ))}
      <text
        style={{
          textAlign: "center",
          fontSize: "50px",
          marginTop: "100px",
          fontFamily: "regular",
        }}
      >
        {courseData?.videos?.length === 0 ? "No Videos found" : null}
      </text>
    </div>
  );
};

export default Course;
