import React, { useState, useEffect } from "react";
import classes from "./Style.module.css";
import location from "../../assets/location.png";
import phone from "../../assets/phone.png";
import mail from "../../assets/mail.png";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  return (
    <div className={classes.container}>
      <img className={classes.home_background} src="./Images/1.webp" alt="" />
      <div className={classes.heading} data-aos="zoom-in">
        <h1>Get in touch.</h1>
      </div>
      <div
        className={classes.sub_container}
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div className={classes.sub_container1}>
          <div className={classes.logo_container}>
            <img className={classes.logo} src={location} alt="location" />
            <div className={classes.content}>
              <h5>Address</h5>
              <p>A 165, West Vinod Nagar, East Delhi - 110092, India</p>
            </div>
          </div>
          <div className={classes.logo_container}>
            <img className={classes.logo} src={phone} alt="phone" />
            <div className={classes.content}>
              <h5>Phone No.</h5>
              <p>+91 97115 44430</p>
            </div>
          </div>
          <div className={classes.logo_container}>
            <img className={classes.logo} src={mail} alt="mail" />
            <div className={classes.content}>
              <h5>E-Mail</h5>
              <p>info@buildfuturelab.com</p>
            </div>
          </div>
        </div>
        <div className={classes.sub_container2}>
          <div className={classes.form_container}>
            <div className={classes.form_subcontainer}>
              <label>Name</label>
              <input
                placeholder=" Your Name..."
                type="text"
                name="name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div className={classes.form_subcontainer}>
              <label>E-mail</label>
              <input
                placeholder="Your E-Mail..."
                type="email"
                name="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className={classes.form_subcontainer}>
              <label>Message</label>
              <input
                placeholder=" Your Message..."
                type="text"
                name="msg"
                value={msg}
                onChange={(e) => setmsg(e.target.value)}
              />
            </div>
          </div>
          <button
            className={classes.send_button}
            onClick={() => {
              const data = {
                service_id: "service_zipw6de",
                template_id: "template_seao4d9",
                user_id: "0G11zYEByoQ_re2-g",
                template_params: {
                  from_name: name,
                  to_name: "Gajanand tiwari",
                  email: email,
                  message: msg,
                },
              };
              axios
                .post("https://api.emailjs.com/api/v1.0/email/send", data)
                .then((res) => {
                  setemail("");
                  setname("");
                  setmsg("");
                  alert(
                    "Your message is sent! You will get a mail back shortly"
                  );
                })
                .catch((err) => console.log(err));
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
