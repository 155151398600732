import classes from "./Style.module.css";
import NavLinks from "./NavLinks";
import { CgMenu } from "react-icons/cg";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
const Mobilenav = () => {
  const [open, setOpen] = useState(false);
  const hamburgerIcon = 
    <CgMenu
      className={classes.Hamburger}
      size="30px"
      color="black"
      onClick={() => setOpen(!open)}
    />
  const closeIcon = 
    <GrClose
      className={classes.Hamburger}
      size="30px"
      color="white"
      onClick={() => setOpen(!open)}
    />
  
const closeMobileMenu =() => setOpen(false)
  return(
     <nav className={classes.Mobilenav}>
       {open ? closeIcon : hamburgerIcon}
       {open && <NavLinks  isMobile={true} closeMobileMenu={closeMobileMenu}/>}
     </nav>
  )
};
export default Mobilenav;
