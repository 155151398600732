import React, { useState, useEffect } from "react";
import classes from "./Style.module.css";
import Card from "./Card";
import "aos/dist/aos.css";
import AOS from "aos";
import axios from "axios";

const Courses = () => {
  const [Data, setData] = useState();
  useEffect(async () => {
    AOS.init();
    const courses = await axios.get(
      "https://buildfuturelabapi.herokuapp.com/api/courses"
    );
    setData(courses.data);
  }, []);

  return (
    <div className={classes.container}>
      <h1 data-aos="fade-up" data-aos-duration="1000">
        Courses
      </h1>
      <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        className={classes.sub_container}
      >
        {Data?.map((i) => (
          <div key={i.id} data-aos="zoom-in" data-aos-duration="1000">
            <Card id={i._id} title={i.title} content={i.content} img={i.img} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
