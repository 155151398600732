import React, { useEffect, useState } from "react";
import classes from "./Style.module.css";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const Blog = () => {
  const [Data, setData] = useState();
  useEffect(async () => {
    AOS.init();
    const blogs = await axios.get(
      "https://buildfuturelabapi.herokuapp.com/api/blogs"
    );
    setData(blogs.data);
  }, []);

  return (
    <div className={classes.container}>
      <h1 data-aos="fade-up" data-aos-duration="1000">
        My Blogs
      </h1>
      <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        className={classes.sub_container}
      >
        {Data?.map((i) => (
          <div key={i._id} data-aos="zoom-in" data-aos-duration="1000">
            <Card
              title={i.title}
              content={i.content}
              img={i.img}
              link={i.link}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
