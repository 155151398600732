import React, { useEffect } from "react";
import classes from "./Style.module.css";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();
  return (
    <div className={classes.home}>
      <img className={classes.home_background} src="./Images/1.webp" alt="" />
      <div className={classes.home_text}>
        <p
          className={classes.main_heading}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Build Future Lab
        </p>
        <p
          className={classes.sub_heading}
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Building your future
        </p>
        <button
          className={classes.home_button}
          onClick={() => navigate("/about")}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          Know more {">"}
        </button>
      </div>
    </div>
  );
}

export default Home;
