import { NavLink } from "react-router-dom";
import classes from "./Style.module.css";
import Logo from "../../assets/mainLogo.jpg";

const NavLinks = (props) => {
  return (
    <div className={classes.NavLinks}>
      <img className={classes.logo} src={Logo} alt="logo" />
      <ul>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.Navlink
            }
          >
            Home
          </NavLink>
        </li>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.Navlink
            }
          >
            About
          </NavLink>
        </li>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <NavLink
            to="/courses"
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.Navlink
            }
          >
            Courses
          </NavLink>
        </li>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.Navlink
            }
          >
            Blogs
          </NavLink>
        </li>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.Navlink
            }
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default NavLinks;
