import React, { useEffect } from "react";
import classes from "./Style.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={classes.about}>
      <img
        className={classes.about__image}
        src="./Images/1.webp"
        alt="About us"
      />
      <div className={classes.about_container}>
        <div className={classes.about__founder}>
          <img
            className={classes.about__img}
            src="./Images/founder.jpeg"
            alt=""
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div
            className={classes.about__content}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1>Founder</h1>
            <p>- Mr. Gajanand Tiwari </p>
          </div>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <h2>Build your skills</h2>
          <div className={classes.line} />
          <p>
            Relationship building skills are a combination of soft skills that a
            person applies to connect with others and form positive
            relationships. In the workplace, relationship building skills are
            essential for getting along with coworkers, contributing to a team
            and building an understanding between yourself and others.
          </p>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h2>Courses</h2>
          <div className={classes.line} />
          <ul>
            <li>Power BI Course</li>
            <li>SRSS Course</li>
            <li>SRIS Course</li>
            <li>SRAS Course</li>
            <li>Basic Excel Course</li>
            <li>Excel VBA Course</li>
            <li>MS Access Course</li>
            <li>SQL</li>
            <li>MySQL</li>
            <li>Python Language</li>
            <li>R Language</li>
          </ul>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h2>Learn at your own pace</h2>
          <div className={classes.line} />
          <p>
            Unlike traditional physical courses, e-learning makes the edge by
            allowing you to learn at your own pace. Which means, once you enroll
            in a course, you can start and complete it at your own leisure time.
            There’s no one forcing you to complete it in a specific amount of
            time.
          </p>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h2>Cost effective</h2>
          <div className={classes.line} />
          <p>
            It’s pretty obvious the physical learning centers charge a hefty
            amount of money. And while that is not wrong, not many people can
            afford it. That’s when online courses come into play. They cost very
            little, sometimes the cost of your lunch, and provide the same
            amount of value, if not more.
          </p>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h2>Unlimited access</h2>
          <div className={classes.line} />
          <p>
            Another great thing about e-learning is, upon enrolling in a course,
            you get it to keep it for a lifetime. The videos, files, and
            everything else that comes with it is there for you to learn at any
            given time.
          </p>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h2>Frequent updates</h2>
          <div className={classes.line} />
          <p>
            Most of the online course instructors care about their students,
            which is why they regularly update the materials with up-to-date
            information. And the best part? You get the updates for free. Yes,
            you read that right.
          </p>
        </div>
        <div
          className={classes.about_content}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h2>Helpful community</h2>
          <div className={classes.line} />
          <p>
            Online courses usually come with a helpful community so that
            students can come together and discuss the course. No matter if you
            have a doubt, a problem, or a win to share, the community is a
            suitable place for that.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
