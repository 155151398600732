import Mobilenav from "./Mobilenav";
import Navigation from "./Navigation";
import classes from "./Style.module.css";

const Navbar = () => {
  return (
    <div className={classes.Navbar}>
      <Mobilenav />
      <Navigation />
    </div>
  );
};
export default Navbar;
