import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import {
  CCard,
  CCardImage,
  CCardBody,
  CCardTitle,
  CCardText,
  CButton,
} from "@coreui/react";

const Card = ({ id, title, content, img }) => {
  return (
    <CCard
      style={{
        marginTop: "50px",
        maxWidth: "400px",
        width: "auto",
      }}
    >
      <CCardImage orientation="top" src={img} />
      <CCardBody>
        <CCardTitle>{title}</CCardTitle>
        <CCardText>{content}</CCardText>
        <CButton color="danger" variant="ghost" href={`/course/${id}`}>
          Start watching
        </CButton>
      </CCardBody>
    </CCard>
  );
};

export default Card;
